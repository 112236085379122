const base = (() => {
    return [
        process.env.VUE_APP_HTTPS === 'true' ? 'https' : 'http',
        '://',
        process.env.VUE_APP_NATIVE_SERVER,
        process.env.port ? ':' + process.env.port : '',
        process.env.VUE_APP_NATIVE_CONTEXT_PATH
    ].join('')
})()

export default {
    url: (source = '') => {
        source = '/' + source.replace(/^\/+/i, '')
        return base + source
    }
}
