const cache = []
window._hmt = []
const _hmt = window._hmt
let disabled = false

setTimeout(function () {
    cache.forEach(function (i) {
        if (_hmt && _hmt.cmd) {
            _hmt.push(cache[i])
        }
    })
}, 5000)

export default {
    disable: function (disable) {
        disabled = disable
    },
    setUp: function () {
        (function () {
            const hm = document.createElement('script')
            hm.src = '//hm.baidu.com/hm.js?3710d25b2f7a4694b95c43aab2b9f4d0'
            const s = document.getElementsByTagName('script')[0]
            s.parentNode.insertBefore(hm, s)
        })()
    },
    trackPageview: function (params) {
        if (disabled) return
        if (_hmt && _hmt.cmd) {
            _hmt.push(['_trackPageview', params.url])
        } else {
            cache.push(['_trackPageview', params.url])
        }
    },
    trackEvent: function (params) {
        if (disabled) return

        if (_hmt && _hmt.cmd) {
            if (params.category) {
                _hmt.push([
                    '_trackEvent',
                    params.category,
                    params.action || params.category,
                    params.opt_label
                ])
            }
        } else {
            cache.push([
                '_trackEvent',
                params.category,
                params.action,
                params.opt_label
            ])
        }
    }
}
