<template>
    <popup-component v-model="popupVisible"
                     @input="syncPopupModelProp"
                     :close-btn="false"
                     :fixed-z-index="true"
                     :z-index="zIndex"
                     :modal="true"
                     v-bind="$attrs">
        <div class="dialog" :style="{width: width}">
            <div class="dialog_hd">
                <slot name="header">
                    <div class="dialog_title" v-if="!!title">{{title}}</div>
                </slot>
            </div>
            <div class="dialog_bd">
                <slot name="body">
                    <div class="dialog_body" :style="{textAlign: textAlign}">
                        <div v-html="content"></div>
                    </div>
                </slot>
            </div>
            <div class="dialog_ft">
                <slot name="footer">
                    <div class="dialog_footer">
                        <a href="javascript:" v-if="useCancel" class="btn btn_cancel" @click.prevent.stop="cancel">{{cancelText}}</a>
                        <a href="javascript:" class="btn btn_ok" @click.prevent.stop="ok">{{okText}}</a>
                    </div>
                </slot>
            </div>
        </div>
    </popup-component>
</template>

<script>
    import PopupUtils from './PopupUtils'

    export default {
        name: 'AlertDialog',
        mixins: [PopupUtils],
        props: {
            zIndex: {
                type: Number,
                default: 500
            },
            title: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: ''
            },
            useCancel: {
                type: Boolean,
                default: false
            },
            cancelText: {
                type: String,
                default: '取消'
            },
            okText: {
                type: String,
                default: '确定'
            },
            width: {
                type: String,
                default: ''
            },
            textAlign: {
                type: String,
                default: ''
            },
            onOk: Function,
            onCancel: Function
        },
        methods: {
            ok () {
                this.dismiss()
                if (typeof this.onOk === 'function') {
                    this.onOk()
                }
            },
            cancel () {
                this.dismiss()
                if (typeof this.onCancel === 'function') {
                    this.onCancel()
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    .dialog {
        background-color: @white;
        border-radius: 10px;
        padding: 15px 16px;
        width: 260px;
        @media screen and (min-width: 321px) {
            width: 300px;
        }

        .dialog_hd {
            position: relative;

            .dialog_title {
                color: @text-color;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-align: center;
            }
        }

        .dialog_bd {
            padding: 20px 0;

            .dialog_body {
                text-align: center;
                word-break: break-all;
                font-size: 14px;
                color: @text-color;
                line-height: 24px;
            }
        }

        .dialog_footer {
            display: flex;
            align-items: center;

            .btn {
                display: block;
                letter-spacing: 1px;
                line-height: 44px;
                height: 44px;
                text-align: center;
                font-size: 14px;
                width: 50%;

                &.btn_ok {
                    font-weight: 600;
                    color: white;
                    background-color: @colorPrimary;

                    &.btn:after {
                        border-color: @colorPrimary;
                    }

                    &.btn:visited:after {
                        border-color: @colorPrimary;
                    }
                }

                &.btn_cancel {
                    color: @text-color;
                    background-color: @white;

                    &.btn:after {
                        border-color: @text-color;
                    }

                    &.btn:visited:after {
                        border-color: @text-color;
                    }
                }

                & + .btn {
                    margin-left: 15px;
                }
            }
        }
    }
</style>
