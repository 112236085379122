import Cookies from 'js-cookie'

class Tokens {
    constructor () {
        this._expiresIn = 0
        let token = Cookies.get('accessToken') || ''

        if (!token) {
            token = localStorage.accessToken || ''
        }

        Cookies.set('accessToken', token, { expires: 60, domain: '.all-yoga.com.cn' })
        localStorage.setItem('accessToken', token)

        this._accessToken = token
        Cookies.remove('refreshToken')
    }

    get expiresIn () {
        return this._expiresIn
    }

    set expiresIn (value) {
        this._expiresIn = value
    }

    get accessToken () {
        return this._accessToken
    }

    set accessToken (value) {
        this._accessToken = value
        Cookies.set('accessToken', value, { expires: 60, domain: '.all-yoga.com.cn' })
        localStorage.setItem('accessToken', value)
        if (!value) {
            Cookies.remove('accessToken')
            localStorage.removeItem('accessToken')
        }
    }

    get refreshToken () {
        return this._refreshToken
    }

    set refreshToken (value) {
        this._refreshToken = value
    }

    fill (tokens) {
        this.accessToken = tokens.access_token
        this.refreshToken = tokens.refresh_token
        this.expiresIn = tokens.expires_in
    }

    clear () {
        this.accessToken = ''
        this.refreshToken = ''
        this.expiresIn = ''
    }
}

export default new Tokens()
