import axios from './axios'
import Api from './api'
import Tokens from './tokens'

function getHeaders (refreshToken) {
    const token = (refreshToken ? Tokens.refreshToken : Tokens.accessToken)
    let Authorization = {}
    if (token) {
        Authorization = { Authorization: `Bearer ${token}` }
        // todo ios9 不允许Bearer加空格的token串  换言之 ios9不允许header值的开始和结束为止出现空白字符
    }

    return {
        ...{
            'CLIENT-ID': Api.getClientId(),
            Accept: Api.accept()
        },
        ...Authorization
    }
}

function makeAxios (method, url = '', data = {}, config = {}, refresh_token = false) {
    data._rand = Math.random()
    if (method === 'get') {
        config.params = data
    }

    config.headers = getHeaders(refresh_token)

    let p
    if (method === 'get') {
        p = axios[method](Api.url(url), config)
    } else {
        p = axios[method](Api.url(url), data, config)
    }

    return p.then(res => res.data)
}

const ajax = {
    get (url, data, config, refresh_token = false) {
        return makeAxios('get', url, data, config, refresh_token)
    },
    post (url, data, config, refresh_token = false) {
        return makeAxios('post', url, data, config, refresh_token)
    }
}

export default ajax
