<template>
    <div class="loading_layer" :style="{zIndex: zIndex}" v-show="show">
        <div class="loading"></div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
            zIndex: Number,
            show: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {}
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    .loading_layer {
        width: 140px;
        height: 160px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        z-index: 9999;
        /* background-color: rgba(255, 255, 255, .9);
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, .4); */
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        animation: rotate .9s linear infinite;
        border: 1px solid @colorPrimary;
        border-left-color: transparent;
        box-sizing: border-box;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
</style>
